import { clsxMerge } from "shared/lib/helpers";
import { CompetitionRibbonIcon } from "shared/ui/icons";

const NumberalRibbon = ({
  value,
  className,
}: {
  value?: number;
  className?: string;
}) => {
  return (
    <span
      className={clsxMerge(
        "relative flex items-center justify-center",
        className
      )}
    >
      <CompetitionRibbonIcon className="w-6" />
      <span className="brand-typography-body2 absolute mt-[-10px] font-semibold text-white">
        {value}
      </span>
    </span>
  );
};

export const LeaderboardRibbon = ({ rank }: { rank?: number }) => {
  return (
    <>
      {rank === 1 && <NumberalRibbon value={rank} className="text-[#E8C407]" />}
      {rank === 2 && <NumberalRibbon value={rank} className="text-[#BDBDBD]" />}
      {rank === 3 && <NumberalRibbon value={rank} className="text-[#C07129]" />}
    </>
  );
};
