import { GetForumPostsConfigI, glencocoClientAPI } from "@/api/glencoco";
import { ForumProvider } from "shared/ui/sales-floor/contexts/forum-context";
import {
  GENERAL_SALES_FLOOR_CAMPAIGN_ID,
  SALES_FLOOR_ENDPOINT_TYPES,
} from "@/constants/forum";

export const GeneralDiscussionsPostsProvider = ({
  children,
}: {
  children?: any;
}) => {
  return (
    <ForumProvider
      campaignId={GENERAL_SALES_FLOOR_CAMPAIGN_ID}
      apiFactory={glencocoClientAPI}
      configLoadPostsRequest={
        {
          endpoint_type: SALES_FLOOR_ENDPOINT_TYPES.TOP_DISCUSSIONS,
        } as GetForumPostsConfigI
      }
    >
      {children}
    </ForumProvider>
  );
};
