import { FC, useEffect, useState } from "react";
import { useRouter } from "next/router";

import Router from "@/helpers/router";
import { useOnboarding } from "@/hooks/use-onboarding";

import { UserGoalTracker } from "@/modules/dashboard-user/dashboard-user-goal-tracker";

interface TopNavConditionalComponentsPropsI {
  className?: string;
}

const PAGES_WITH_GOAL_TRACKER = [
  Router.dashboard(),
  Router.home(),
  Router.performanceDashboard(),
];

export const TopNavConditionalComponents: FC<
  TopNavConditionalComponentsPropsI
> = ({ className }) => {
  const { pathname } = useRouter();
  const { isOnboarded } = useOnboarding();

  const [isGoalTrackerVisible, setIsGoalTrackerVisible] = useState(false);

  useEffect(() => {
    setIsGoalTrackerVisible(
      isOnboarded && PAGES_WITH_GOAL_TRACKER?.includes(pathname)
    );
  }, [isOnboarded, pathname]);

  return (
    <div className={className}>
      {isGoalTrackerVisible && <UserGoalTracker />}
    </div>
  );
};
