import { Container } from "@/components/base/container";
import { DashboardUserTitle as Title } from "./dashboard-user-title";
import { DashboardUserPerformance as Performance } from "@/components/modules/dashboard-user/dashboard-user-performance";
import { DashboardUserCampaignHighlightsSection as CampaignHighlights } from "@/components/modules/dashboard-user/dashboard-user-campaigns-highlights";
import { DashboardUserGeneralDicussionsSection as GeneralDicussions } from "./dashboard-user-general-discussions";
import { DashboardUserLeaderboard as Leaderboard } from "@/components/modules/dashboard-user/dashboard-user-leaderboard";

export const DashboardUser = () => {
  return (
    <Container className="!max-w-[1082px] pb-5">
      <Title className="mb-12" />

      <CampaignHighlights className="mb-5" />
      <Performance className="mb-5" />
      <Leaderboard classNames="mb-5" />
      <GeneralDicussions className="mb-20" />
    </Container>
  );
};
