import { FC } from "react";
import clsx from "clsx";
import { Avatar } from "shared/ui";
import { RESUME_PLACEHOLDER_IMAGE } from "shared/ui/sales-resume/profile";
import { LeaderboardItemI } from "@/interfaces/leaderboards";
import { clsxMerge } from "shared/lib/helpers";

import { LeaderboardRibbon } from "./ribbon";

interface TableRowI {
  data?: LeaderboardItemI;
  rank?: number;
  isYou?: boolean;
  onClick?: () => void;
}

const getFormattedRank = (rank = 0) => {
  const lastDigit = rank % 10;

  switch (lastDigit) {
    case 1:
      return `${rank}st Place`;
    case 2:
      return `${rank}nd Place`;
    case 3:
      return `${rank}rd Place`;
    case 0:
      return `-`;
    default:
      return `${rank}th Place`;
  }
};

const TableRow: FC<TableRowI> = ({ data, rank, isYou, onClick }) => {
  const handleRowClick = () => {
    onClick?.();
  };

  if (!data) {
    return null;
  }

  return (
    <tr
      className={clsx("hover cursor-pointer", {
        disabled: false,
        "!bg-[#E4ECFF]": isYou,
      })}
      onClick={handleRowClick}
    >
      <td className={clsxMerge("text-black/60")}>
        <div className="grid grid-cols-[30px_60px] items-center gap-1">
          <span>{(rank || 0) <= 3 && <LeaderboardRibbon rank={rank} />}</span>

          <div className="flex items-center">
            <p className="min-w-[60px]">{getFormattedRank(rank)}</p>
            <Avatar
              src={data.profile_pic_url || RESUME_PLACEHOLDER_IMAGE}
              className="mx-2"
            />
            {isYou && (
              <span className="brand-typography-body3 text-black/40">You</span>
            )}
          </div>
        </div>
      </td>

      <td>
        <p className="ae-link">{data?.user_slug}</p>
      </td>

      <td>{data?.qualified_meetings}</td>

      <td className="text-black/60">{(data?.hold_rate || 0) * 100}%</td>
    </tr>
  );
};

export default TableRow;
