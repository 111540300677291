import { useEffect, CSSProperties, useRef, useState, FC } from "react";
import { APII, glencocoClientAPI } from "@/api/glencoco";

import Router from "@/helpers/router";

import { clsxMerge } from "shared/lib/helpers";
import { useApi } from "shared/lib/hooks";
import { CheckCircleIcon } from "shared/ui/icons";
import { LocalStorage } from "@/helpers/local-storage";
import { FeatureIntroTooltip } from "shared/ui/feature-intro/tooltip-guide";

interface CatchupTodosButtonPropsI {
  className?: string;
  isLoading?: boolean;
  isHowToVisible?: boolean;
}

const todoCountFetcher = (API: APII) => API.getTodoCount();

const CATCHUPS_BUTTON_ID = "catchups-button";

export const CatchupTodosButton: FC<CatchupTodosButtonPropsI> = ({
  className,
  isLoading: isUpdateTriggerActive,
  isHowToVisible = false,
}) => {
  const lsRef = useRef(new LocalStorage());
  const [isCatchupsWalkthroughCTAVisible, setIsCatchupsWalkthroughCTAVisible] =
    useState(false);
  const [{ data, isLoading }, fetchCount] = useApi({
    apiFactory: glencocoClientAPI,
    fetcher: todoCountFetcher,
  });
  const [isCatchupsHowToVisible, setIsCatchupsHowToVisible] = useState(
    lsRef.current.isCatchupsWalkthroughCompleted && isHowToVisible
  );

  const markCatchupsWalkthroughAsCompleted = () => {
    setIsCatchupsHowToVisible(true);
    lsRef.current.isCatchupsWalkthroughCompleted = true;
  };

  const { count = 0 } = data || {};

  useEffect(() => {
    if (isUpdateTriggerActive) {
      void fetchCount();
    }
  }, [isUpdateTriggerActive]);

  useEffect(() => {
    if (count > 0 && !lsRef.current.isCatchupsWalkthroughCompleted) {
      setIsCatchupsWalkthroughCTAVisible(true);
    }
  }, [count]);

  return (
    <>
      <div>
        <a
          id={CATCHUPS_BUTTON_ID}
          style={
            {
              "--pulse-alt-start-color": "rgba(0,0,0,1)",
              "--pulse-alt-end-color": "rgba(0,0,0,0)",
            } as CSSProperties
          }
          className={clsxMerge(
            "btn-ae-default min-w-[220px] uppercase",
            {
              loading: isLoading,
              "disabled !bg-[#2DA831] !text-black": !isLoading && count === 0,
              "animate-pulse-alt": !isLoading && count > 0,
            },
            className
          )}
          href={Router.session()}
        >
          {!isLoading && (
            <>
              {count > 0 && (
                <div className="animate-fadein">
                  <span className="mr-2">Today’s to-do list</span>
                  <span className="ae-typography-detail1 badge rounded-[4px] border-none bg-[#F88167] text-white">
                    {count}
                  </span>
                </div>
              )}

              {count === 0 && (
                <div className="flex animate-fadein items-center">
                  <CheckCircleIcon className="w-4" />
                  <span className="ml-2">TO-DO LIST COMPLETED</span>
                </div>
              )}
            </>
          )}
        </a>

        {isCatchupsHowToVisible && (
          <a href={Router.catchupsWalkthrough()} className="ae-link ml-3">
            Learn how to-do's work
          </a>
        )}
      </div>

      {isCatchupsWalkthroughCTAVisible && (
        <FeatureIntroTooltip
          referenceId={CATCHUPS_BUTTON_ID}
          placement="bottom"
          title="Take a look out our new to-do list feature"
          description="Now you have a simpler way to followup on missed calls, callbacks, scheduled meetings and other timely tasks "
          buttons={{
            primary: {
              text: "Check It Out",
              actionHandler: () => {
                markCatchupsWalkthroughAsCompleted();
                window.location.href = Router.catchupsWalkthrough();
              },
            },
            secondary: {
              text: "View Later",
              actionHandler: (onClose) => {
                markCatchupsWalkthroughAsCompleted();
                onClose();
              },
            },
          }}
        />
      )}
    </>
  );
};
