import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Swiper as SwiperI } from "swiper/types";

import { StatsItem } from "shared/ui/cards/stats-card";
import { clsxMerge, formatNumbers, pluralizeNoun } from "shared/lib/helpers";
import {
  ClockOutlineIcon,
  PersonasChatIcon,
  PhoneChatIcon,
} from "shared/ui/icons";
import { BanknotesIcon, StarIcon } from "@heroicons/react/24/outline";
import { SwiperNavigationDots } from "shared/ui/swiper/swiper-navigation-dots";

export interface DashboardPerformanceTilesPropsI {
  calls_made: number;
  hours_spent_on_campaign: number;
  qualified_meetings_booked: number;
  show_up_rate: string | number;
  total_earnings_in_usd: number;
}

export const DashboardPerformanceList = ({
  calls_made,
  hours_spent_on_campaign,
  qualified_meetings_booked,
  show_up_rate,
  total_earnings_in_usd,
}: DashboardPerformanceTilesPropsI) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [swiper, setSwiper] = useState<SwiperI>();

  const swiperSetActiveIndex = (index: number) => {
    setActiveIndex(index);
    swiper?.slideTo(index);
  };

  const onSwiperNavClick = (index: number) => {
    swiperSetActiveIndex(index);
  };

  const handlerOnSlideChange = (swiper: SwiperI) => {
    setActiveIndex(swiper?.activeIndex);
  };

  return (
    <div className={clsxMerge("duration-1000 animate-in fade-in")}>
      <Swiper
        onSwiper={setSwiper}
        slidesPerView={4.3}
        allowTouchMove={true}
        onSlideChange={handlerOnSlideChange}
        slidesOffsetBefore={24}
      >
        <SwiperSlide>
          <StatsItem
            icon={<PersonasChatIcon color="#fff" width={22} />}
            value={formatNumbers(qualified_meetings_booked || 0, 3, {
              notation: "compact",
            })}
            valueClassName="text-[36px] leading-none"
            label={`Qualified ${pluralizeNoun(
              "Meeting",
              qualified_meetings_booked || 0
            )}`}
            className="bg-gradient-to-b from-[#EB9B75] to-[#FC95BA] text-white"
          />
        </SwiperSlide>

        <SwiperSlide>
          <StatsItem
            icon={<PhoneChatIcon color="#fff" width={22} />}
            value={formatNumbers(hours_spent_on_campaign || 0, 3, {
              notation: "compact",
            })}
            valueClassName="text-[36px] leading-none lowercase"
            label={`${pluralizeNoun(
              "Hour",
              hours_spent_on_campaign || 0
            )} on Campaigns`}
            className="bg-black text-white"
          />
        </SwiperSlide>

        <SwiperSlide>
          <StatsItem
            icon={<StarIcon color="#fff" width={22} />}
            value={formatNumbers(calls_made || 0, 3, {
              notation: "compact",
            })}
            valueClassName="text-[36px] leading-none lowercase"
            label={`${pluralizeNoun("Call", calls_made || 0)} made`}
            className="bg-black text-white"
          />
        </SwiperSlide>

        <SwiperSlide>
          <StatsItem
            icon={<ClockOutlineIcon color="#fff" width={22} />}
            value={formatNumbers(show_up_rate || 0, 3, {
              notation: "compact",
            })}
            valueClassName="text-[36px] leading-none lowercase"
            label="Show up rate"
            className="bg-black text-white"
          />
        </SwiperSlide>

        <SwiperSlide>
          <StatsItem
            icon={<BanknotesIcon color="#fff" width={22} />}
            value={formatNumbers(total_earnings_in_usd || 0, 3, {
              notation: "compact",
            })}
            valueClassName="text-[36px] leading-none lowercase"
            label="Total earnings"
            className=" bg-black text-white"
          />
        </SwiperSlide>
      </Swiper>

      <div className="mt-6 flex items-center justify-center">
        <SwiperNavigationDots
          onClick={onSwiperNavClick}
          activeIndex={activeIndex}
          length={2}
        />
      </div>
    </div>
  );
};
