import { FC, Fragment, useState } from "react";
import ReactSlider from "react-slider";
import _omit from "lodash/omit";

import { clsxMerge } from "shared/lib/helpers";

interface RangeSliderPropsI {
  className: string;
  defaultStep?: number;
  onChange?: (v: number) => void;
}

const baseTrackClassName = "h-[5px] bg-black/5";

const Track = ({ className }: { className?: string }) => (
  <div className={clsxMerge("flex w-full gap-1.5", className)}>
    <div
      className={clsxMerge(
        baseTrackClassName,
        "w-[24%] rounded-l bg-[#DAEBE0]"
      )}
    />
    <div className={clsxMerge(baseTrackClassName, "w-[56%] bg-[#A1D5B1]")} />
    <div
      className={clsxMerge(
        baseTrackClassName,
        "w-[20%] rounded-r bg-[#55A16D]"
      )}
    />
  </div>
);

const Ticks = ({ className }: { className?: string }) => (
  <div className={clsxMerge("relative w-full text-black/40", className)}>
    <div className={clsxMerge("absolute left-[13.5%]")}>
      <span className="ae-typography-body1">4</span>
    </div>
    <div className={clsxMerge("absolute left-[46.5%]")}>
      <span className="ae-typography-body1">12</span>
    </div>
    <div className={clsxMerge("absolute right-[16.2%]")}>
      <span className="ae-typography-body1">20+</span>
    </div>
  </div>
);

export const RangeSlider: FC<RangeSliderPropsI> = ({
  className,
  defaultStep,
  onChange,
}) => {
  const [step, setStep] = useState(defaultStep ?? 1);

  const handleOnChange = (value: number) => {
    setStep(value);
    onChange?.(value);
  };

  return (
    <section className={clsxMerge("relative h-10", className)}>
      <Track className="absolute left-0 top-2" />
      <Ticks className="absolute left-0 top-[22px]" />

      <ReactSlider
        className="range-no-tail"
        defaultValue={step}
        min={1}
        max={24}
        thumbClassName="h-6 w-6"
        trackClassName="w-full"
        thumbActiveClassName="!cursor-grabbing"
        onChange={handleOnChange}
        renderThumb={(props) => (
          // Wrapping into a Fragment as a workaround for the issue with ReactSlider (key is passed to JSX with props).
          // See https://github.com/zillow/react-slider/issues/288 for details.
          <Fragment key={props.key}>
            <div
              {..._omit(props, ["key"])}
              className={clsxMerge(
                "relative h-6 w-6 rounded-full border-[3px] border-black bg-white",
                "transition-all duration-300",
                "hover:cursor-grab"
              )}
            />
          </Fragment>
        )}
      />
    </section>
  );
};
