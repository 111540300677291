import { useMemo } from "react";
import { clsxMerge } from "shared/lib/helpers";

export const SwiperNavigationDots = ({
  length = 1,
  activeIndex = 0,
  className,
  activeClassName,
  onClick = () => {},
}: {
  length?: number;
  activeIndex?: number;
  className?: string;
  activeClassName?: string;
  onClick?: (index: number) => void;
}) => {
  const lengthArr = useMemo(() => new Array(length).fill(0), [length]);

  return (
    <div className={clsxMerge("animate-fadein flex gap-1", className)}>
      {lengthArr.map((_step, i) => (
        <div
          key={i}
          className={clsxMerge(
            "h-4 w-4 cursor-pointer rounded-full",
            "transition-all duration-300",
            {
              [clsxMerge("bg-black/80", activeClassName)]: activeIndex === i,
              "bg-black/20": activeIndex !== i,
            }
          )}
          onClick={() => onClick(i)}
        />
      ))}
    </div>
  );
};
