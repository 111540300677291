import { FC, useEffect } from "react";
import toast from "react-hot-toast";

import { clsxMerge, formatNumbers, pluralizeNoun } from "shared/lib/helpers";
import {
  ONBOARDING_GOAL_MODAL_ID,
  OnboardingGoalModal,
} from "@/components/modals/onboarding-goal-modal";
import { useGoalTracker } from "@/hooks/use-goal-tracker";
import { Spinner } from "shared/ui/spinners/spinner";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { useFetchFirstCallTime } from "@/api/routes/performance/queries";
import { useLocalStorage } from "@/context/local-storage";
import dayjs from "dayjs";

interface UserGoalTrackerPropsI {
  className?: string;
}

export const UserGoalTracker: FC<UserGoalTrackerPropsI> = ({ className }) => {
  const {
    isFetching: isFetchingGoalTracker,
    progress,
    weeklyQualifiedMeetingsTarget,
    weeklyQualifiedMeetings,

    updateGoalTrackerMetrics,
    isUpdatingGoalTrackerMetrics,
  } = useGoalTracker();

  const storage = useLocalStorage();

  const firstCallTimeQuery = useFetchFirstCallTime(
    !storage.goalTrackerShownAfterFirstCall
  );

  useEffect(() => {
    // first call time is null if there are no calls made
    const firstCallTime = firstCallTimeQuery.data?.data?.first_call_time;

    if (
      !storage.goalTrackerShownAfterFirstCall &&
      firstCallTimeQuery.isSuccess &&
      firstCallTime
    ) {
      // Show goal tracker modal if the user has no weekly qualified meetings
      // target and the first call time has been made within last 7 days
      if (
        weeklyQualifiedMeetingsTarget === 0 &&
        dayjs(firstCallTime).isBetween(dayjs().subtract(7, "day"), dayjs())
      ) {
        modalHelpers.open(ONBOARDING_GOAL_MODAL_ID);
      }

      storage.goalTrackerShownAfterFirstCall = "true";
    }
  }, [firstCallTimeQuery.isSuccess, firstCallTimeQuery.data]);

  const handleGoalModal = (qualifiedMeetings: number) => {
    if (isUpdatingGoalTrackerMetrics) {
      return;
    }

    updateGoalTrackerMetrics({
      qualified_meetings: qualifiedMeetings,
    })
      .then(() =>
        toast.success("Weekly qualified meetings goal has been updated!")
      )
      .catch(() => toast.error("Failed to update qualified meetings goal!"));
  };

  const handleOpenGoalTrackerModal = () => {
    if (isFetchingGoalTracker) {
      return;
    }

    modalHelpers.open(ONBOARDING_GOAL_MODAL_ID);
  };

  return (
    <>
      <button
        onClick={handleOpenGoalTrackerModal}
        className={clsxMerge(
          "relative h-[32px] w-[250px] rounded-[4px] bg-black/2",
          "bg-[#D1FFE9]",
          "animate-fadein transition-all duration-300 ",
          "block",
          !isFetchingGoalTracker && "cursor-pointer",
          className
        )}
      >
        <div
          className={clsxMerge(
            "h-full rounded-[4px] bg-black/5",
            "transition-all duration-300",
            "bg-[#67F8B2]"
          )}
          style={{ width: `${progress}%` }}
        />

        <div className="absolute top-0 flex h-full w-full items-center justify-between px-3">
          <span className="b-typography-body1 flex min-w-[120px] items-center font-semibold">
            Goal Tracker
          </span>

          {isFetchingGoalTracker ? (
            <Spinner />
          ) : (
            <span className="b-typography-body1 animate-fadein font-normal">
              {formatNumbers(weeklyQualifiedMeetings)}/
              {formatNumbers(weeklyQualifiedMeetingsTarget)}{" "}
              {pluralizeNoun("QM", weeklyQualifiedMeetingsTarget || 0)}
            </span>
          )}
        </div>
      </button>

      <OnboardingGoalModal
        weeklyQualifiedMeetingsTarget={weeklyQualifiedMeetingsTarget}
        onSuccess={handleGoalModal}
      />
    </>
  );
};
