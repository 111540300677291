import { useEffect, useState } from "react";
import { clsxMerge, fixUrl } from "shared/lib/helpers";
import { getProfileURL } from "shared/ui/sales-resume/helper";
import TableRow from "@/components/modules/dashboard-user/dashboard-user-leaderboard/table/table-row";
import { LeaderboardItemI } from "@/interfaces/leaderboards";

export const DashboardUserLeaderboardTable = ({
  data,
  user,
}: {
  data?: LeaderboardItemI[];
  user?: LeaderboardItemI;
}) => {
  const [tableData, setTableData] = useState(data);

  const handleTableRowClick = (username?: string) => {
    if (username && window !== undefined) {
      window.location.href = fixUrl(getProfileURL(username)) || "";
    }
  };

  useEffect(() => {
    setTableData(data);
  }, [data]);

  return (
    <div className="relative mt-4 rounded-lg">
      <section
        className={clsxMerge(
          "card z-[1] bg-white/70 px-3 lg:card-side",
          "transition-opacity duration-500",
          {
            "opacity-0": false,
            "opacity-100": true,
          }
        )}
      >
        <div className={clsxMerge("relative w-full")}>
          {!tableData?.length && (
            <div className="hero absolute pt-40 text-black/40">
              Currently no data. Callers like you are currently booking meetings
              and making the ranks!
            </div>
          )}

          <table className="table-ae w-full">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Username</th>
                <th>Qualified meetings</th>
                <th>Hold rate</th>
                {/* <th></th> */}
              </tr>
            </thead>

            <tbody
              className={clsxMerge(
                "ae-typography-body2 overflow-y-auto",
                "h-[284px]"
              )}
            >
              {tableData?.map((data, i) => {
                return (
                  <TableRow
                    key={i}
                    data={data}
                    isYou={data?.user_slug === user?.user_slug}
                    rank={data?.ranking}
                    onClick={() => handleTableRowClick(data?.user_slug)}
                  />
                );
              })}

              {!!tableData?.length &&
                !tableData?.some(
                  (usr) => usr.user_slug === user?.user_slug
                ) && (
                  <TableRow
                    isYou={true}
                    data={user}
                    rank={user?.ranking}
                    onClick={() => handleTableRowClick(user?.user_slug)}
                  />
                )}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};
