import _mapValues from "lodash/mapValues";

/**
 * Raw numbers
 * 10 Qualified Meeting =
 * 11 Show Meetings =
 * 16 Booked Meetings
 * 300 Conversations
 * 2300 Dials
 *
 * So normalized:
 * 1 Qualified Meeting =
 * 1.1 Show Meetings =
 * 1.6 Booked Meetings
 * 30 Conversations
 * 230 Dials
 *
 */

export const estimateGoalMetricsByQualifiedMeetings = (
  qualifiedMeetings = 0
) => {
  const goalTrackerCoefficients = {
    timeOnDialer: 2.3, // in hours, 305 minutes / 60 minutes * 1.2 (correction multiplier from Jason)
    dials: 230,
    conversations: 30,
    bookedMeetings: 1.6,
    showUps: 1.5,
  };

  return _mapValues(
    goalTrackerCoefficients,
    (value) => value * qualifiedMeetings
  );
};
