import Router from "@/helpers/router";
import Link from "next/link";
import { clsxMerge } from "shared/lib/helpers";
import { GeneralDiscussionsPostsProvider as PostsProvider } from "./posts-provider";
import { PostsSwiper } from "./posts-swiper";
import { useCampaign } from "@/hooks/use-campaign";
import { GENERAL_SALES_FLOOR_CAMPAIGN_ID } from "@/constants/forum";
import { LoadingComponent } from "@/modules/loading";
import { UserDashboardSection } from "@/modules/dashboard-user/shared/section";

export const DashboardUserGeneralDicussionsSection = ({
  className,
}: {
  className?: string;
}) => {
  const { campaign, isLoading } = useCampaign(GENERAL_SALES_FLOOR_CAMPAIGN_ID);

  return (
    <UserDashboardSection
      className={clsxMerge(className, "min-h-[321px] px-0")}
    >
      <div className="mb-5 flex items-center justify-between px-7">
        <h5 className="brand-typography-h5">General Discussions</h5>

        <Link
          href={Router.salesFloorGeneralByCategory()}
          className="brand-typography-body3 ae-link font-semibold"
        >
          View Community Sales Floor
        </Link>
      </div>

      {isLoading && <LoadingComponent className="h-[250px]" />}

      {!isLoading && (
        <div className="animate-fadein">
          <PostsProvider>
            <PostsSwiper campaign={campaign} />
          </PostsProvider>
        </div>
      )}
    </UserDashboardSection>
  );
};
