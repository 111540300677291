import { CatchupTodosButton } from "@/components/shared/catchup-todos-button";
import { useGlobalContext } from "@/hooks/use-global-context";
import { clsxMerge } from "shared/lib/helpers";

export const DashboardUserTitle = ({ className }: { className?: string }) => {
  const { glencocoUser: user } = useGlobalContext();

  return (
    <div className={clsxMerge(className, "flex")}>
      <div>
        <h1 className="brand-typography-h1 mb-5 mr-6">
          Welcome back, {user?.first_name}
        </h1>

        <p className="brand-typography-body1 text-black/60">
          Let’s get you caught up.
        </p>
      </div>

      <div>
        <CatchupTodosButton className="btn-ae-l" isHowToVisible />
      </div>
    </div>
  );
};
