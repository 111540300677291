import { useState, FC } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { OnboardingGoalRangeSlider } from "@/modules/onboarding/onboarding-goal-range-slider";
import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";

interface OnboardingGoalModalPropsI {
  weeklyQualifiedMeetingsTarget: number;
  onSuccess?: (qualifiedMeetings: number) => any;
}

export const ONBOARDING_GOAL_MODAL_ID = "onboarding-goal-modal";

const DEFAULT_QUALIFIED_MEETINGS_GOAL = 1;

export const OnboardingGoalModalContent: FC<
  ModalChildrenFnPropsI & OnboardingGoalModalPropsI
> = ({ weeklyQualifiedMeetingsTarget, onSuccess, handleClose }) => {
  const [isSaveGoalDisabled, setIsSaveGoalDisabled] = useState(false);

  const [qualifiedMeetingsGoal, setQualifiedMeetingsGoal] = useState(0);

  const handleConfirmation = async () => {
    setIsSaveGoalDisabled(true);
    await onSuccess?.(qualifiedMeetingsGoal);
    setIsSaveGoalDisabled(false);
    handleClose();
  };

  const handleOnChangeGoalSlider = (qualifiedMeetings: number) => {
    setQualifiedMeetingsGoal(qualifiedMeetings);
  };

  return (
    <div className="px-4 pb-2 pt-6">
      <h5 className="mb-2 font-medium typography-header-5-semibold">
        Select how many qualified meetings you intend to book each week
      </h5>

      <OnboardingGoalRangeSlider
        onChange={handleOnChangeGoalSlider}
        defaultQualifiedMeetings={
          weeklyQualifiedMeetingsTarget || DEFAULT_QUALIFIED_MEETINGS_GOAL
        }
      />

      <div className="flex items-center justify-end pt-6">
        <button className="btn-ae-text" onClick={handleClose}>
          Cancel
        </button>

        <button
          className={clsxMerge("btn-ae-default btn-ae-l w-[220px] uppercase", {
            isSaveGoalDisabled,
          })}
          onClick={handleConfirmation}
          disabled={isSaveGoalDisabled}
        >
          Save your goal
        </button>
      </div>
    </div>
  );
};

export const OnboardingGoalModal: FC<OnboardingGoalModalPropsI> = (props) => (
  <Modal
    id={ONBOARDING_GOAL_MODAL_ID}
    modalBoxClassName="w-[650px]"
    unmountOnClose
  >
    {(modalProps) => <OnboardingGoalModalContent {...modalProps} {...props} />}
  </Modal>
);
