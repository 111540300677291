import { FC, useEffect, useMemo, useState } from "react";

import { RangeSlider } from "./range-slider";
import { GoalEstimations } from "./goal-estimations";
import { clsxMerge } from "shared/lib/helpers";

interface OnboardingGoalRangeSliderPropsI {
  defaultQualifiedMeetings?: number;
  onChange?: (qualifiedMeetings: number) => void;
}

const QUALIFIED_MEETINGS_PER_STEP = 1;
const AVERAGE_QUALIFIED_MEETINGS = 6;
const ABOVE_AVERAGE_QUALIFIED_MEETINGS = 20;

export const OnboardingGoalRangeSlider: FC<OnboardingGoalRangeSliderPropsI> = ({
  defaultQualifiedMeetings = 0,
  onChange,
}) => {
  const [step, setStep] = useState(defaultQualifiedMeetings);

  const qualifiedMeetings = useMemo(
    () => step * QUALIFIED_MEETINGS_PER_STEP,
    [step]
  );

  const remark = useMemo(() => {
    if (qualifiedMeetings >= ABOVE_AVERAGE_QUALIFIED_MEETINGS) {
      return "an above-average";
    }

    return qualifiedMeetings >= AVERAGE_QUALIFIED_MEETINGS
      ? "an average"
      : "a below-average";
  }, [qualifiedMeetings]);

  useEffect(() => {
    onChange?.(qualifiedMeetings);
  }, [qualifiedMeetings]);

  return (
    <section>
      <div
        className={clsxMerge(
          "badge h-[30px]",
          "rounded-lg border-none",
          "bg-[#E6EDFF] font-medium text-black",
          "ml-10 px-4 py-1"
        )}
      >
        {qualifiedMeetings} qualified meetings per week, is {remark} goal rate
      </div>

      <RangeSlider className="mb-8" defaultStep={step} onChange={setStep} />

      <GoalEstimations qualifiedMeetings={qualifiedMeetings} />
    </section>
  );
};
