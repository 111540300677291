import { useMemo, useState } from "react";

import { useEffectOnce } from "shared/lib/hooks";
import { clsxMerge, formatNumbers } from "shared/lib/helpers";
import Link from "next/link";
import Router from "@/helpers/router";
import { DashboardPerformanceList } from "@/components/modules/dashboard-user/dashboard-user-performance/performance-list";
import { UserDashboardSection } from "@/modules/dashboard-user/shared/section";
import { GetDashboardUserStatsResponseI } from "@/api/routes/dashboard";
import { glencocoClientAPI } from "@/api/glencoco";

export const DashboardUserPerformance = ({
  className,
}: {
  className?: string;
}) => {
  const [performanceData, setPerformanceData] =
    useState<GetDashboardUserStatsResponseI>({
      qualified_meetings_booked: 0,
      hours_spent_on_campaign: 0,
      calls_made: 0,
      total_earnings_in_usd: 0,
      show_up_rate: 0,
    });

  const {
    qualified_meetings_booked,
    hours_spent_on_campaign,
    calls_made,
    total_earnings_in_usd,
  } = useMemo(() => performanceData, [performanceData]);

  const show_up_rate = useMemo(
    () =>
      performanceData?.show_up_rate
        ? formatNumbers(performanceData.show_up_rate * 100) || 0
        : 0,
    [performanceData?.show_up_rate]
  );

  const getPerformanceData = async () => {
    const API = glencocoClientAPI();

    const resp = await API.getDashboardUserStats().catch(() => ({
      status: null,
      data: null,
    }));

    if (resp.status === 200) {
      setPerformanceData(resp.data);
    }
  };

  useEffectOnce(() => {
    void getPerformanceData();
  });

  return (
    <UserDashboardSection className={clsxMerge("px-0", className)}>
      <div className="mb-6 flex justify-between px-7">
        <h5 className="brand-typography-h5">Performance</h5>
        <Link
          href={Router.settingsProfile()}
          className="brand-typography-h6 ae-link"
        >
          View Sales Resume
        </Link>
      </div>

      <DashboardPerformanceList
        calls_made={calls_made}
        hours_spent_on_campaign={hours_spent_on_campaign}
        qualified_meetings_booked={qualified_meetings_booked}
        show_up_rate={show_up_rate}
        total_earnings_in_usd={total_earnings_in_usd}
      />
    </UserDashboardSection>
  );
};
