import { useCallback, useMemo, useState } from "react";
import { useGlobalContext } from "@/hooks/use-global-context";
import { CampaignV3I } from "@/interfaces/campaign";

import uniqBy from "lodash/uniqBy";
import take from "lodash/take";

import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperI } from "swiper/types";
import { SwiperNavigationDots } from "shared/ui/swiper/swiper-navigation-dots";
import "swiper/css";

import { useForumContext } from "shared/ui/sales-floor/contexts/forum-context";
import { SalesFloorPost } from "@/modules/sales-floor/post";
import { clsxMerge } from "shared/lib/helpers";

const swiperClassNames =
  "[&_.swiper-slide]:pl-7 [&_.swiper-slide:last-child]:pr-7";

export const PostsSwiper = ({ campaign }: { campaign?: CampaignV3I }) => {
  const [swiper, setSwiper] = useState<SwiperI>();
  const [activeIndex, setActiveIndex] = useState(0);

  const { posts: _posts } = useForumContext();
  const { glencocoUser: user } = useGlobalContext();

  const onOpenPost = useCallback((postId: string) => {
    console.log(postId);
  }, []);

  const onClosePost = useCallback(() => {}, []);

  const posts = useMemo(() => take(uniqBy(_posts, "id"), 3), [_posts]);

  const swiperSetActiveIndex = (index: number) => {
    setActiveIndex(index);
    swiper?.slideTo(index);
  };

  const onSwiperNavClick = (index: number) => {
    swiperSetActiveIndex(index);
  };

  const handlerOnSlideChange = (swiper: SwiperI) => {
    setActiveIndex(swiper?.activeIndex);
  };

  if (!campaign || !user) {
    return null;
  }

  return (
    <div className={clsxMerge(swiperClassNames)}>
      <Swiper
        onSwiper={setSwiper}
        allowTouchMove={true}
        slidesPerView={1.3}
        onSlideChange={handlerOnSlideChange}
      >
        {posts.map((post) => (
          <SwiperSlide key={post.id}>
            <SalesFloorPost
              key={post.id}
              className="mr-1 min-w-[740px] border border-[#C2C2C2]"
              post={post}
              currentUserId={user.id}
              onOpenPost={onOpenPost}
              onClosePost={onClosePost}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="mt-6 flex items-center justify-center">
        <SwiperNavigationDots
          onClick={onSwiperNavClick}
          activeIndex={activeIndex}
          length={3}
        />
      </div>
    </div>
  );
};
