import { APII, glencocoClientAPI } from "@/api/glencoco";
import { useCallback } from "react";
import { useApi } from "shared/lib/hooks";

/**
 * Load single campaing that is not attached to anything
 * that have no side effects
 */
export const useCampaign = (campaignId?: string) => {
  const fetcher = useCallback(
    (API: APII) => API.getCampaignsV3({ campaign_ids: [campaignId as string] }),
    [campaignId]
  );

  const [{ data, isLoading }] = useApi({
    fetcher,
    apiFactory: glencocoClientAPI,
    errorBuilder: () => "Failed to get Campaign",
  });

  return { campaign: data?.campaigns?.[0], isLoading };
};
