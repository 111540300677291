import { FC, ReactNode } from "react";
import { clsxMerge } from "shared/lib/helpers";

interface UserDashboardSectionPropsI {
  className?: string;
  children?: ReactNode;
}

export const UserDashboardSection: FC<UserDashboardSectionPropsI> = ({
  className,
  children,
}) => (
  <section
    className={clsxMerge(
      "rounded-lg border-[0.5px] border-[#C2C2C2] bg-white",
      "p-7",
      "duration-500 animate-in fade-in zoom-in-75",
      className
    )}
  >
    {children}
  </section>
);
