import { clsxMerge } from "shared/lib/helpers";
import { ClockIcon } from "shared/ui/icons";
import { DayJs } from "shared/lib/helpers/date";
import { useMemo } from "react";
import { LoadingComponent } from "@/components/modules/loading";

export const NextPayoutBlock = ({ endDate }: { endDate?: string }) => {
  const timeLeft = useMemo(() => {
    const date1 = DayJs(endDate);
    const date2 = DayJs();

    const diff = date1.diff(date2, "day", true);

    const days = Math.floor(diff);
    const hours = Math.floor((diff - days) * 24);

    if (days < 0 || hours < 0) {
      return <LoadingComponent className="ml-2 inline-block p-0 pl-3 " />;
    }

    return `${days} day${days > 1 ? "s" : ""} ${hours} hour${
      hours > 1 ? "s" : ""
    }`;
  }, [endDate]);

  return (
    <div
      className={clsxMerge(
        "rounded-md bg-black/10 px-2 py-1",
        "flex items-center"
      )}
    >
      <ClockIcon className="mr-3 w-4" />
      <span className="brand-typgoraphy-body1 flex items-center">
        <span className="mr-1">Next payout in </span>
        <b className="font-semibold">{timeLeft}</b>
      </span>
    </div>
  );
};
