import Link from "next/link";
import { clsxMerge } from "shared/lib/helpers";

import { useApi } from "shared/lib/hooks";
import { APII, glencocoClientAPI } from "@/api/glencoco";

import { UserDashboardSection } from "@/modules/dashboard-user/shared/section";

import Router from "@/helpers/router";
import { LoadingComponent } from "@/modules/loading";
import { CampaignCard } from "shared/ui";

import { useMemo } from "react";
import take from "lodash/take";
import { CAMPAIGN_CARD_TYPES } from "shared/ui/cards/campaign-card";

const campaignsFetcher = (API: APII) => API.getDashboardCampaigns();

export const DashboardUserCampaignHighlightsSection = ({
  className,
}: {
  className?: string;
}) => {
  const [{ data, isLoading }] = useApi({
    apiFactory: glencocoClientAPI,
    fetcher: campaignsFetcher,
  });

  const campaigns = useMemo(() => take(data?.campaigns, 3), [data?.campaigns]);

  return (
    <UserDashboardSection className={clsxMerge(className)}>
      <div className="mb-5 flex items-center justify-between">
        <h5 className="brand-typography-h5">Campaigns Highlights 🔥</h5>

        <Link
          href={Router.marketplace()}
          className="brand-typography-body3 ae-link font-semibold"
        >
          View All Campaigns
        </Link>
      </div>

      {isLoading && <LoadingComponent className="h-[330px]" delay={300} />}

      {!isLoading && (
        <div className="grid grid-cols-3 gap-x-3">
          {campaigns?.map((c) => (
            <Link href={Router.marketplaceCampaign(c.id)} key={c?.id}>
              <CampaignCard
                type={CAMPAIGN_CARD_TYPES.HIGHLIGHTS}
                {...c}
                className={clsxMerge(
                  "border-[0.5px] border-[#C2C2C2]",
                  "btn-free",
                  "duration-500 animate-in fade-in"
                )}
              />
            </Link>
          ))}
        </div>
      )}
    </UserDashboardSection>
  );
};
