import { FC, useMemo, useState } from "react";
import CountUp from "react-countup";

import { clsxMerge, pluralizeNoun } from "shared/lib/helpers";
import { estimateGoalMetricsByQualifiedMeetings } from "./utils";

interface DataBlockPropsI {
  value?: number;
  decimals?: number;
  label?: string;
  className?: string;
  labelClassName?: string;
  valueClassName?: string;
}

interface GoalEstimationsPropsI {
  qualifiedMeetings?: number;
}

export const DataBlock: FC<DataBlockPropsI> = ({
  value,
  decimals,
  label,
  className,
  labelClassName,
  valueClassName,
}) => {
  const [prev, setPrev] = useState(0);

  return (
    <div className={clsxMerge("flex justify-between text-black", className)}>
      {label && (
        <span className={clsxMerge(labelClassName ?? "typography-body-3")}>
          {label}
        </span>
      )}

      {typeof value === "number" && (
        <span
          className={clsxMerge(
            valueClassName ?? "typography-header-8-semibold"
          )}
        >
          <CountUp
            start={prev}
            end={value || 0}
            duration={1}
            decimals={decimals || 0}
            onEnd={((value) => () => {
              setPrev(value);
            })(value)}
          />
        </span>
      )}
    </div>
  );
};

export const GoalEstimations: FC<GoalEstimationsPropsI> = ({
  qualifiedMeetings,
}) => {
  const { showUps, bookedMeetings, conversations, dials, timeOnDialer } =
    useMemo(
      () => estimateGoalMetricsByQualifiedMeetings(qualifiedMeetings),
      [qualifiedMeetings]
    );

  return (
    <div>
      <h6 className="typography-header-8-semibold">
        Based on Glencoco's averages, here's what it takes to achieve{" "}
        {qualifiedMeetings} {pluralizeNoun("QM", qualifiedMeetings || 0)}
      </h6>

      <div className="mt-6 flex flex-col gap-6">
        <DataBlock value={timeOnDialer} label="Time on dialer (hrs)" />
        <DataBlock value={dials} label="Dials" />
        <DataBlock value={conversations} label="Conversations" />
        <DataBlock value={bookedMeetings} label="Meetings booked" />
        <DataBlock value={showUps} label="Showed" />
        <DataBlock
          value={qualifiedMeetings}
          label="Qualified meetings"
          labelClassName="!typography-header-8-bold"
          valueClassName="!typography-header-6"
        />
      </div>
    </div>
  );
};
