import { FC, ReactElement } from "react";
import clsx from "clsx";

interface StatsItemPropsI {
  className?: string;
  isPublic?: boolean;
  icon: ReactElement;
  value?: string | number;
  valueClassName?: string;
  label: string;
}

export const StatsItem: FC<StatsItemPropsI> = ({
  className,
  isPublic = true,
  icon,
  value,
  valueClassName,
  label,
}) => (
  <div
    className={clsx(
      "flex flex-col justify-between",
      "w-[224px] min-w-[155px]",
      "px-[14px] py-[18px]",
      "rounded-[20px]",
      className
    )}
  >
    {icon}

    <div className="flex flex-col">
      <span
        className={clsx("mt-3 font-semibold", valueClassName, {
          "blur-md": !isPublic,
        })}
      >
        {value ? value : "N/A"}
      </span>
      <span className="text-[12px] font-semibold lg:text-[14px]">{label}</span>
    </div>
  </div>
);
