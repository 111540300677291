import { useMemo } from "react";
import Link from "next/link";

import { APII, glencocoClientAPI } from "@/api/glencoco";
import { useApi } from "shared/lib/hooks";
import Router from "@/helpers/router";
import { DashboardUserLeaderboardTable as Table } from "@/components/modules/dashboard-user/dashboard-user-leaderboard/table";
import { UserDashboardSection } from "@/modules/dashboard-user/shared/section";

import take from "lodash/take";
import { LoadingComponent } from "@/modules/loading";
import { NextPayoutBlock } from "./next-payout-block";
import { safeGetDate } from "shared/lib/helpers";
import { DATE_FORMAT_MONTH_SLASH_DAY } from "shared/lib/constants/time";

import { ArrowLongRightIcon } from "@heroicons/react/20/solid";

const leaderboardFetcher = (API: APII) => API.getDashboardLeaderboard();

export const DashboardUserLeaderboard = ({
  classNames,
}: {
  classNames?: string;
}) => {
  const [{ data, isLoading }] = useApi({
    apiFactory: glencocoClientAPI,
    fetcher: leaderboardFetcher,
  });

  const [leaderboardData, userStanding, startDate, endDate] = useMemo(
    () => [
      take(data?.leaderboard, 5),

      data?.my_standing,
      data?.start_date,
      data?.end_date,
    ],
    [data]
  );

  return (
    <UserDashboardSection className={classNames}>
      <div className="mb-4 flex items-center justify-between pr-5">
        <h5 className="brand-typography-h5 flex items-center">
          <span className="mr-1">Leaderboards for the week of</span>
          <span>{safeGetDate(startDate, DATE_FORMAT_MONTH_SLASH_DAY)}</span>
          <ArrowLongRightIcon className="mx-2 w-4" />
          <span>{safeGetDate(endDate, DATE_FORMAT_MONTH_SLASH_DAY)}</span>
        </h5>

        <NextPayoutBlock endDate={endDate} />

        <div className="flex">
          <Link
            href={Router.bonuses()}
            target="_blank"
            className="brand-typography-detail2 ae-link"
          >
            Learn about our bonus program
          </Link>

          <div className="divider divider-horizontal m-0" />

          <Link
            href={Router.leaderboard()}
            className="brand-typography-h6 ae-link"
          >
            View All
          </Link>
        </div>
      </div>

      {isLoading && <LoadingComponent className="h-[330px]" delay={300} />}

      {!isLoading && <Table data={leaderboardData} user={userStanding} />}
    </UserDashboardSection>
  );
};
